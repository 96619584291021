





























import { Component, Vue, Prop } from 'vue-property-decorator';
import { SubscriptionContract } from '@/gen';

@Component
export default class UnsubscribeComplete extends Vue {
  @Prop({ required: true })
  readonly contractInfo!: SubscriptionContract;

  @Prop({ required: true })
  readonly packageInfo!: SubscriptionContract;
}
