
















































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class UnsubscribeConfirmDialog extends Vue {
  @Prop({ required: true })
  readonly value!: boolean;

  @Prop({ required: true })
  readonly buttonLoading!: boolean;

  get wrapperDialog() {
    return this.value;
  }
  set wrapperDialog(flag: boolean) {
    this.$emit('input', flag);
  }
}
