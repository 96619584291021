









































import { Component, Vue } from 'vue-property-decorator';

import { SubscriptionContract } from '@/gen/api';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import { SubscriptionRepository } from '@/repositories/SubscriptionRepository';
import { SubscriptionPackageCmsRepository } from '@/repositories/SubscriptionPackageCmsRepository';
import UnsubscribeComplete from '@/components/subscription/UnsubscribeComplete.vue';
import UnsubscribeConfirm from '@/components/subscription/UnsubscribeConfirm.vue';
import { getSubscriptionStatusByPackageId } from '@/common/subscriptionUtils';
import { INFO_SUBSCRIPTION_CLAIMKEY } from '@/common/constants';

@Component({
  components: {
    UnsubscribeConfirm,
    UnsubscribeComplete
  }
})
export default class P0225 extends Vue {
  // 解約予約完了画面表示フラグ
  showComplete = false;
  // パッケージ ID
  packageId = '';
  // パッケージ情報
  packageInfo = {} as SubscriptionPackageItem;
  // サブスク登録情報
  contractInfo = {} as SubscriptionContract;
  // microCMS or odin からのデータ取得時にエラーが発生した場合のエラーメッセージ
  fetchErrMsg = '';
  // サブスク解約予約 API 呼び出し時にエラーが発生した場合のエラーメッセージ
  unsubscribeErrMsg = '';
  // 解約ボタン複数回押下防止フラグ
  buttonLoading = false;
  // 解約予約確認モーダル表示フラグ
  isUnsubscribeConfirmDialogOpening = false;

  created() {
    this.initialize();
  }

  async initialize() {
    this.packageId = this.$route.params.id;
    // params からパッケージ ID の取得に失敗した場合、サブスク状態表示画面へリダイレクトする
    if (!this.packageId) {
      this.$router.push({ name: 'manage-subscription' });
    }

    this.getPackage();
    await this.getInfo();

    // fetchErrMsg にメッセージがセットされていれば、後続処理をスキップして画面描画する
    if (this.fetchErrMsg !== '') {
      return;
    }
    // 解約予約対象のパッケージに、登録中かつ解約予約をしていないユーザ以外は、サブスク状態表示画面へリダイレクトする
    if (
      !(
        getSubscriptionStatusByPackageId(
          this.$auth.user[INFO_SUBSCRIPTION_CLAIMKEY],
          this.packageId
        ) === this.$cls.SUBSCRIPTION_STATUS_CLS.REGISTERING.CD &&
        this.contractInfo.continue_flg
      )
    ) {
      this.$router.push({ name: 'manage-subscription' });
    }
  }

  // サブスクリポジトリ for odin
  get subscriptionRepo() {
    return new SubscriptionRepository();
  }
  // サブスクリポジトリ for microCMS
  get subscriptionCmsRepo() {
    return new SubscriptionPackageCmsRepository();
  }

  // パッケージ情報の取得 from microCMS
  async getPackage() {
    const item = await this.subscriptionCmsRepo.select(this.packageId);
    if (item) {
      this.packageInfo = item;
    } else {
      this.fetchErrMsg = this.$msg.get('2000078');
    }
  }
  // サブスク登録情報の取得 from odin
  async getInfo() {
    await this.subscriptionRepo
      .getSubscriptionInfo()
      .then(res => {
        const info = res.getSubscriptionInfoByPackageId(this.packageId);
        if (info) {
          this.contractInfo = info;
        } else {
          // 基本的には起こり得ないが、getSubscriptionInfoByPackageId()の実行結果が undefined を返却する可能性があるため、info が undefined の場合は Error() を throw する
          throw new Error();
        }
      })
      .catch((errCode: number) => {
        if (errCode !== 42206) {
          // パッケージに登録中でない場合はサブスク状態表示画面へリダイレクトするためエラーメッセージをセットしない
          this.fetchErrMsg = this.$msg.get('2000078');
        }
      });
  }
  // サブスク解約予約処理実行
  submitUnsubscribe() {
    if (this.buttonLoading) {
      return;
    }
    this.buttonLoading = true;
    this.subscriptionRepo
      .updateSubscriptionUnsubscribe(this.$route.params.id)
      .then(() => {
        this.showComplete = true;
      })
      .catch((errCode: number) => {
        this.unsubscribeErrMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
      })
      .finally(() => {
        this.buttonLoading = false;
        this.isUnsubscribeConfirmDialogOpening = false;
      });
  }
}
