














































































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

import { SubscriptionContract } from '@/gen/api';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import UnsubscribeConfirmDialog from '@/components/subscription/UnsubscribeConfirmDialog.vue';
import { SUBSCRIPTION_PACKAGE_NOT_UPDATE } from '@/common/constants';

@Component({
  components: {
    UnsubscribeConfirmDialog
  }
})
export default class UnsubscribeConfirm extends Vue {
  notForUpdatePackage: string = SUBSCRIPTION_PACKAGE_NOT_UPDATE;

  @Prop({ required: true })
  readonly packageInfo!: SubscriptionPackageItem;

  @Prop({ required: true })
  readonly contractInfo!: SubscriptionContract;

  @Prop({ required: true })
  readonly buttonLoading!: boolean;

  @Prop({ required: true })
  readonly unsubscribeErrMsg!: boolean;

  @PropSync('isUnsubscribeConfirmDialogOpening', { required: true })
  syncIsUnsubscribeConfirmDialogOpening!: boolean;
}
